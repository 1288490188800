import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 100 100" {...props}>
        <path d="M93.9 92.1c-2.8-.7-7.2-.7-12.7-.6-8.8.2-21 .4-33-2.8l-5.6-1.5-32.3-7.6c-.6-.3-1.9-.6-2.3-.9 0-.2.1-.6.3-1.3 1.1-3.9 7.1-3.7 8.6-3.3h.2l16.6 2.6c.1.1.1.2.2.4 1 1.5 2.9 1.8 4.8 2l15.1 3h.1c.6.1 3.7.4 7.3.4 3.9 0 8.3-.4 10.9-2 .7-.4.9-1.4.5-2.1-.4-.7-1.4-.9-2.1-.5-3.4 2.1-12.7 1.5-16.2 1.1l-15.1-3c-.9-.1-2.6-.4-2.8-.7 0-.1-.3-.5.2-2.4.5-2 3.4-3.8 7.7-3.3l10.8 2.1.3-.1c.2-.1 23-6.6 37.2 4.3.7.5 1.6.4 2.1-.3s.4-1.6-.3-2.1c-6.4-4.9-15.3-7.3-25.8-6.9-6.9.3-12.2 1.6-13.6 2l-10.1-2h-.1c-6.1-.7-10.2 2.2-11 5.5-.1.4-.2.9-.3 1.4l-15.8-2.4c-3.6-.8-10.8-.1-12.3 5.6-.3 1.2-.7 2.7.2 3.9.7.9 2 1.3 3.9 1.8L41.9 90l5.6 1.5c12.5 3.3 24.8 3.1 33.8 2.9 5.3-.1 9.5-.2 11.9.5.1 0 .3.1.4.1.7 0 1.3-.4 1.4-1.1.2-.8-.3-1.6-1.1-1.8zM50 17.7c-8 0-13.8 6-13.8 14.2 0 4.8 2.2 7.9 4 10.4 1.3 1.9 2.5 3.5 2.5 5.4 0 1.2.6 2.1 1.6 2.8-.3.1-.7.3-.9.6-.5.7-.3 1.6.3 2.1.1.1.3.2.5.3-.3.1-.6.3-.8.5-.5.7-.3 1.6.3 2.1.8.6 1.7 1 2.5 1.2.4 1.8 2 3.1 3.9 3.1s3.5-1.3 3.9-3.1c.8-.2 1.7-.6 2.5-1.2.7-.5.8-1.4.3-2.1-.2-.3-.5-.5-.8-.6.2-.1.3-.2.4-.3.7-.5.8-1.4.3-2.1-.2-.3-.6-.5-.9-.6.9-.7 1.6-1.6 1.6-2.8 0-1.9 1.1-3.5 2.5-5.4 1.8-2.5 4-5.7 4-10.4-.1-8.1-5.9-14.1-13.9-14.1zm7.4 22.9c-1.5 2.1-3 4.3-3 7.1 0 .5-2.3 1.6-4.4 1.6s-4.4-1.1-4.4-1.6c0-2.8-1.5-5-3-7.1-1.7-2.4-3.4-4.9-3.4-8.7 0-6.5 4.6-11.2 10.8-11.2s10.8 4.7 10.8 11.2c0 3.8-1.7 6.3-3.4 8.7z" />
        <path d="M49.6 22.9c-3.6 0-6.3 1.7-7.3 4.7-.2.8.2 1.6 1 1.9.1 0 .3.1.5.1.6 0 1.2-.4 1.4-1 .7-2.3 3.3-2.6 4.4-2.6.8 0 1.5-.7 1.5-1.5 0-.9-.7-1.6-1.5-1.6zM52 11.1c0-1.1-.9-2-2-2s-2 .9-2 2c0 .6.3 1.1.7 1.5-.4.4-.7.9-.7 1.5 0 1.1.9 2 2 2s2-.9 2-2c0-.6-.3-1.1-.7-1.5.4-.4.7-.9.7-1.5zM40.5 15c.2-.5.1-1.1-.2-1.6-.6-.9-1.8-1.2-2.8-.6-.9.6-1.2 1.8-.6 2.8.3.5.8.8 1.4.9-.2.5-.1 1.1.2 1.6.6.9 1.8 1.2 2.8.6.9-.6 1.2-1.8.6-2.8-.3-.5-.9-.8-1.4-.9zM30.1 25.4c.5.2 1.1.2 1.7 0 .2.5.5 1 1.1 1.2 1 .4 2.2 0 2.6-1s0-2.2-1-2.6c-.5-.2-1.1-.2-1.7 0-.2-.5-.5-1-1.1-1.2-1-.4-2.2 0-2.6 1-.5.9-.1 2.1 1 2.6zM32.6 36.8c1.1-.2 1.8-1.2 1.7-2.3-.2-1.1-1.2-1.8-2.3-1.7-.6.1-1.1.4-1.4.9-.4-.4-1-.5-1.6-.4-1.1.2-1.8 1.2-1.7 2.3.2 1.1 1.2 1.8 2.3 1.7.6-.1 1.1-.4 1.4-.9.4.3 1 .5 1.6.4zM35.4 42.5c-.4.4-.7 1-.7 1.5-.6-.1-1.1.1-1.6.5-.8.7-.9 2-.1 2.8s2 .9 2.8.1c.4-.4.7-1 .7-1.5.6.1 1.1-.1 1.6-.5.8-.7.9-2 .1-2.8s-2-.8-2.8-.1zM61.7 16.4c.6-.1 1.1-.4 1.4-.9.6-.9.3-2.2-.6-2.8-.9-.6-2.2-.3-2.8.6-.3.5-.4 1.1-.2 1.6-.6.1-1.1.4-1.4.9-.6.9-.3 2.2.6 2.8s2.2.3 2.8-.6c.3-.5.3-1 .2-1.6zM67.2 26.5c.5-.2.9-.7 1.1-1.2.5.2 1.1.3 1.7 0 1-.4 1.5-1.6 1-2.6-.4-1-1.6-1.5-2.6-1-.5.2-.9.7-1.1 1.2-.5-.2-1.1-.3-1.7 0-1 .4-1.5 1.6-1 2.6s1.6 1.5 2.6 1zM71 33.3c-.6-.1-1.2.1-1.6.4-.3-.5-.8-.8-1.4-.9-1.1-.2-2.1.6-2.3 1.7-.2 1.1.6 2.1 1.7 2.3.6.1 1.2-.1 1.6-.4.3.5.8.8 1.4.9 1.1.2 2.1-.6 2.3-1.7.1-1.1-.6-2.1-1.7-2.3zM65.3 44c0-.6-.2-1.1-.7-1.5-.8-.7-2.1-.7-2.8.1-.7.8-.7 2.1.1 2.8.4.4 1 .6 1.6.5 0 .6.2 1.1.7 1.5.8.7 2.1.7 2.8-.1.7-.8.7-2.1-.1-2.8-.4-.4-1-.5-1.6-.5z" />
    </svg>
);

export default SvgComponent;
