import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 66 66" {...props}>
        <path d="M57.5 22.4c-2.9 0-5.3 2.4-5.3 5.3v7.1c-1.2-.1-2.4.3-3.3 1.1l-2 2L38.4 23c-.2-.3-.5-.5-.9-.5s-.7.2-.9.5l-3.3 5.8v-4.3-6.7l2.6-2.6C43 14.9 48.7 9.1 48.7 2c0-.6-.4-1-1-1-7.1 0-12.9 5.7-13.2 12.7l-1.2 1.2V14c0-.6-.4-1-1-1s-1 .4-1 1v7.9l-1.1-1.1c-.1-3.4-1.5-6.5-3.8-8.9C23.9 9.4 20.6 8 17.1 8c-.6 0-1 .4-1 1 0 7.1 5.7 12.9 12.7 13.2l2.6 2.6v4.9l-2.4-3c-.2-.2-.5-.4-.8-.4-.3 0-.6.2-.8.4L19.2 38l-2-2c-.9-.9-2.1-1.3-3.3-1.1v-7.1c0-2.9-2.4-5.3-5.3-5.3-1.4 0-2.6 1.2-2.6 2.6v21c0 1.1.3 2.2 1 3.1 2.4 3.7 3.5 4.4 3.5 6.2v1.3H10c-1.1 0-2 .9-2 2V63c0 1.1.9 2 2 2h18.6c1.1 0 2-.9 2-2v-4.5c0-1.1-.9-2-2-2h-.4c-.3-2.9 1.3-8.3-3.3-12.9l-4.3-4.3 7.6-10.5 3.4 4.2c.2.3.5.4.9.4.3 0 .6-.2.8-.5l4.3-7.5 7.9 13.9c-2.6 3.2-7.6 5.6-7.6 12.3v4.9h-.4c-1.1 0-2 .9-2 2V63c0 1.1.9 2 2 2H56c1.1 0 2-.9 2-2v-4.5c0-1.1-.9-2-2-2h-.4c.1-.8-.2-2 .6-3.3L59 49c.6-.9 1-2 1-3.1V25c.1-1.4-1.1-2.6-2.5-2.6zM39.8 6.3c1.9-1.9 4.3-3 6.9-3.2-.4 4.7-3.9 8.6-8.4 9.8l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-4 4c.4-2 1.4-3.8 2.9-5.2zM18.1 10c4.7.4 8.6 3.9 9.8 8.4l-4-4c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4c-4.5-1.1-8-5-8.4-9.8zm10.5 53v1-1zm-.1 0H10v-4.4h18.6V63zm-2.3-6.4H12.3v-1.3c0-2.7-1.7-3.8-3.8-7.3-.4-.6-.6-1.3-.6-2V25c0-.3.3-.6.6-.6 1.8 0 3.3 1.5 3.3 3.3v7.9c-1.9 1.5-2.1 4.3-.4 5.9l2.9 2.9c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4-2.4-2.6-3.5-2.9-3.5-4.3 0-1.8 2.1-2.6 3.4-1.4l7.7 7.8c4 3.9 2.6 8.3 2.9 11.5zM56 63v1-1zm0 0H37.4v-4.4H56V63zm2.1-17c0 .7-.2 1.4-.6 2l-2.8 4.2c-1.3 1.9-.9 3.6-1 4.4H39.8v-4.9c0-6 4.7-7.9 10.5-14.3 1.8-1.8 4.6.9 2.8 2.8L50.2 43c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l2.9-2.9c1.7-1.7 1.5-4.5-.4-5.9v-7.9c0-1.8 1.5-3.3 3.3-3.3.3 0 .6.3.6.6v21z" />
        <path d="M38 38.9c.2.3.5.5.8.5.2 0 .4-.1.5-.2.5-.3.6-.9.3-1.4l-1.9-2.9c-.3-.5-.9-.6-1.4-.3-.5.3-.6.9-.3 1.4l2 2.9zM27.6 37l-1.9 2.9c-.3.5-.2 1.1.3 1.4.2.1.4.2.5.2.3 0 .7-.2.8-.5l1.9-2.9c.3-.5.2-1.1-.3-1.4-.4-.3-1-.1-1.3.3zM42.1 59.8h-1c-.6 0-1 .4-1 1s.4 1 1 1h1c.6 0 1-.4 1-1s-.4-1-1-1zM24.9 59.8h-1c-.6 0-1 .4-1 1s.4 1 1 1h1c.6 0 1-.4 1-1s-.5-1-1-1z" />
    </svg>
);

export default SvgComponent;
