import styled from 'styled-components';

export const Card = styled.div`
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

export const CardImage = styled.div`
    text-align: center;
    svg,
    img {
        width: ${({ width }) => width || '60px'};
    }
`;

export const CardTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    margin: 0.5rem;
`;

export const CardContent = styled.div`
    font-size: 0.9rem;
    font-family: Open Sans, sans-serif;
    margin: 0.5rem;
    color: ${({ theme }) => theme.yellowDark};
`;

export default {
    Card,
    CardImage,
    CardTitle,
    CardContent,
};
