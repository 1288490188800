import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 66 66" {...props}>
        <path d="M31.4 22.9c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0zM42.4 37.1c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0zM23.1 33.7c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4-.4-.3-1.1-.3-1.4 0zM52 33.6c.4-.4.4-1.1 0-1.4-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4.4.4 1.1.4 1.4 0zM47.5 51.6c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0zM34.4 51.6c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4-.3-.4-1-.4-1.4 0zM31.4 47.4c2.3 0 4.2-1.9 4.2-4.2S33.7 39 31.4 39c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2zm0-6.4c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2zM50.5 44.2c0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2S57 40 54.7 40s-4.2 1.9-4.2 4.2zm4.2-2.2c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2 1-2.2 2.2-2.2zM45 26.4c2.4 0 4.3-1.9 4.2-4.5v-.1c-.4-5.3-8.4-4.9-8.4.4 0 2.3 1.9 4.2 4.2 4.2zm0-2c-1.2 0-2.2-1-2.2-2.2 0-.9.5-1.6 1.3-2 1.4-.6 3 .1 3.1 1.8.1 1.4-1 2.4-2.2 2.4zM15.4 27.7c2-1.2 2.7-3.7 1.5-5.7-1.2-2-3.7-2.7-5.7-1.5-2 1.2-2.7 3.7-1.5 5.7 1.1 2 3.6 2.7 5.7 1.5zm-3.2-5.5c1.1-.6 2.4-.2 3 .8.6 1.1.2 2.4-.8 3-1 .6-2.4.2-3-.8-.7-1.1-.3-2.4.8-3zM17 14.1c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.4-.4-1-.4-1.4 0-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3zM13 42.5c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4-.3-.4-1-.4-1.4 0zM7.9 32.6c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0z" />
        <path d="M25.3 52.9c4.4 4.4 10.2 6.8 16.4 6.8 6.5 0 12.8-2.8 17.2-7.6 4.4-4.8 6.6-11.3 6-17.8 0-.4-.3-.7-.6-.8-1.8-.7-3.2-2.2-3.6-4.1-.1-.4-.4-.7-.9-.7-2.1-.2-3.8-1.5-4.7-3.4-.9-1.9-.8-4 .3-5.8.4-.7-.1-1.5-.9-1.5-2.3 0-4.3-1.3-5.4-3.3-.6-1.2-6.5-1.6-8.4-1.5-7.4-7.4-18.9-8.9-28.1-3.6-5.4 3.1-9.2 8.1-10.8 14.2-1.6 6-.8 12.3 2.3 17.7 4.3 7.2 12.3 11.8 21.2 11.4zm22.2-37c1.2 2 3.2 3.4 5.4 3.8-.8 2-.7 4.3.3 6.3 1.1 2.3 3.2 3.9 5.6 4.4.7 2 2.2 3.7 4.1 4.6.4 5.7-1.6 11.4-5.5 15.7-4.1 4.5-9.7 7-15.8 7-11.8 0-21.3-9.6-21.3-21.3.1-14.2 13.8-24.4 27.2-20.5zM3.7 24.1c1.5-5.5 5-10.1 9.9-12.9 7.9-4.6 17.6-3.6 24.4 2.2-11.1 1.8-19.6 11.4-19.6 23 0 5.3 1.8 10.4 5.1 14.5-7.3-.3-13.9-4.2-17.6-10.6C3 35.4 2.3 29.6 3.7 24.1z" />
    </svg>
);

export default SvgComponent;
