import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 100 100" {...props} stroke="black">
        <path d="M33.13,16.48a10.25,10.25,0,1,0-.33,7.84A10.18,10.18,0,0,0,33.13,16.48Zm-2.15,7a8.25,8.25,0,1,1,.27-6.31A8.2,8.2,0,0,1,31,23.48Z" />
        <path d="M27,19H24V15a1,1,0,0,0-2,0v5a1,1,0,0,0,1,1h4a1,1,0,0,0,0-2Z" />
        <path d="M89,28H75.79a.41.41,0,0,0,.24-.11l2.71-2.53a1,1,0,0,0,0-1.39l-12-13a1,1,0,0,0-.71-.33,1,1,0,0,0-.73.28l-2.71,2.69a.94.94,0,0,0,0,1.34l1,1.07H60V14a1,1,0,0,0-1-1H56V12a1,1,0,0,0-1-1H47a1,1,0,0,0-1,1V33a1,1,0,0,0,1,1H89a1,1,0,0,0,1-1V29A1,1,0,0,0,89,28ZM66,13.08,76.65,24.63l-1.29,1.29L64.69,14.37Zm8.6,14.78a.45.45,0,0,0,.3.14H65V17.55ZM63,18V28H60V18Zm-5-3V28H56V15ZM54,28H52V13h2ZM50,13V28H48V13ZM88,32H48V30H88Z" />
        <path d="M90,55a1,1,0,0,0-1-1H71V53a4.92,4.92,0,0,0,0-9.61V43a1,1,0,0,0-1-1H62a1,1,0,0,0-1,1V54H46V50.29A7.3,7.3,0,0,0,38.71,43H27.29A7.3,7.3,0,0,0,20,50.29V54H11a1,1,0,0,0-1,1V89a1,1,0,0,0,2,0V60h8.79a7.29,7.29,0,0,0,6.5,4H32v6H24a4,4,0,0,0,0,8h8v7.35l-8.41,3.74a1,1,0,0,0,.81,1.83L33,87.09l8.59,3.82a1,1,0,0,0,.81-1.83L34,85.35V78h8a4,4,0,0,0,0-8H34V64h4.71a7.29,7.29,0,0,0,6.5-4H66V89a1,1,0,0,0,1,1H89a1,1,0,0,0,1-1V60a1,1,0,0,0-.15-.5A1,1,0,0,0,90,59ZM44,74a2,2,0,0,1-2,2H24a2,2,0,0,1,0-4H42A2,2,0,0,1,44,74Zm24-4H88v8H68Zm4.88-21.81A3,3,0,0,1,71,50.91V45.46A3,3,0,0,1,72.88,48.19ZM63,44h6V54H63ZM12,58V56h8v.71A7.28,7.28,0,0,0,20.12,58Zm26.71,4H27.29A5.3,5.3,0,0,1,22,56.71V50.29A5.3,5.3,0,0,1,27.29,45H38.71A5.3,5.3,0,0,1,44,50.29v6.42A5.3,5.3,0,0,1,38.71,62Zm7.17-4A7.28,7.28,0,0,0,46,56.71V56H88v2ZM88,60v8H68V60ZM68,88V80H88v8Z" />
        <path d="M76,65h4a1,1,0,0,0,0-2H76a1,1,0,0,0,0,2Z" />
        <path d="M80,73H76a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" />
        <path d="M80,83H76a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" />
    </svg>
);

export default SvgComponent;
