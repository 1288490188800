import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 128 128" {...props}>
        <path d="M100.319 110.342a53.056 53.056 0 0 1-17.625 13.338H128v-13.338h-27.681z" />
        <path d="M108.192 75.902c0-26.345-21.435-47.779-47.779-47.779-14.874 0-28.178 6.837-36.943 17.527a10.66 10.66 0 0 1-4.402-2.455c-2.104-1.957-3.441-4.725-3.441-7.818 0-5.91 4.806-10.716 10.716-10.716.915 0 1.801.137 2.663.355l-4.129 8.365 9.095-3.087 12.604-4.275-.313-.915-7.04-20.784-4.259 8.622a23.813 23.813 0 0 0-8.622-1.618c-13.264 0-24.053 10.791-24.053 24.053 0 7.932 3.876 14.958 9.816 19.341a23.962 23.962 0 0 0 4.319 2.543 47.505 47.505 0 0 0-3.79 18.641c0 5.848 1.1 11.52 3.152 16.926l-8.891 3.848 21.308 8.428 8.434-21.301-8.609 3.725c-1.339-3.715-2.058-7.609-2.058-11.625 0-18.993 15.453-34.442 34.442-34.442s34.442 15.45 34.442 34.442c0 18.99-15.453 34.439-34.442 34.439H0v13.338h60.413c26.345 0 47.779-21.432 47.779-47.778z" />
    </svg>
);

export default SvgComponent;
