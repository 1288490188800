import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 64 80" {...props}>
        <title>{'telescope-astronomy-star-planet-observation-explore'}</title>
        <path d="M8.189 43.286a1 1 0 0 0 .9.564 1.012 1.012 0 0 0 .437-.1l3.817-1.85a1 1 0 0 0 .464-1.336l-.8-1.645 5.114-2.48 1.892 3.952a1 1 0 0 0 .592.52 1.028 1.028 0 0 0 .31.048.994.994 0 0 0 .475-.12l2.19-1.184c.081.134.158.271.244.4L18.242 60H17v2h8v-2h-.682l4.439-15.853a10.968 10.968 0 0 0 1.243.427V60h-1v2h8v-2h-1V44.574a10.968 10.968 0 0 0 1.243-.427L41.682 60H41v2h8v-2h-1.242l-5.584-19.944a10.912 10.912 0 0 0 1.233-9.591l18.03-8.75a1 1 0 0 0 .463-1.336L53.262 2.564a1 1 0 0 0-1.336-.464L30.517 12.489a1 1 0 0 0-.463 1.336l1.021 2.107-17.593 8.506a1 1 0 0 0-.467 1.332l.58 1.211-1.018.493-2.286-5.335 3.156-1.578-.894-1.789-8 4 .894 1.789L8.5 23.035l2.277 5.313-2.343 1.135-.8-1.646a1 1 0 0 0-1.334-.463l-3.817 1.851a1 1 0 0 0-.463 1.336zM42 34a9 9 0 1 1-9-9 9.011 9.011 0 0 1 9 9zM22.242 60h-1.924l5.064-18.083a11.034 11.034 0 0 0 1.57 1.261zM34 60h-2V44.949c.33.03.662.051 1 .051s.67-.021 1-.051zm9.758 0l-4.71-16.822a11.034 11.034 0 0 0 1.57-1.261L45.682 60zm15.906-39.648l-2.017.979-7.766-16.015 2.018-.98zM48.082 6.189l7.765 16.016L42.6 28.637a11.06 11.06 0 0 0-5.479-4.831l-4.831-9.953zM31.948 17.731l2.614 5.393A10.964 10.964 0 0 0 22.71 37.852l-1.345.727-6.116-12.774zM14.459 28.785l2.8 5.849-5.122 2.484-2.83-5.835zm-8.186.825l5.3 10.926-2.018.978-5.3-10.926z" />
        <path d="M28 34a5 5 0 1 0 5-5 5.006 5.006 0 0 0-5 5zm8 0a3 3 0 1 1-3-3 3 3 0 0 1 3 3zM53.606 28.081l-7 3a1 1 0 0 0-.438 1.474 3.407 3.407 0 0 1-.424 4.287l-.451.451 1.414 1.414.451-.451a5.416 5.416 0 0 0 1.219-5.756l6.017-2.579zM55.553 27.105l2-1 .894 1.79-2 .999z" />
    </svg>
);

export default SvgComponent;
