import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import Helmet from 'react-helmet';
import mapProps from 'recompose/mapProps';

import AppBar from '../AppBar';
import Footer from '../Footer';
import MaxWidthContainer from '../MaxWidthContainer';
import TopBar from '../TopBar';
import CardBase from '../Card';
import { PrimaryLink } from '../Button';
import HeaderImage from '../HeaderImage';

import {
    LeanStartup,
    DesignThinking,
    Scrum,
    Teamwork,
    PackageFactory,
    Barrier,
    KnowledgeSharing,
    Telescope,
    UserLove,
} from '../Icons';

/* eslint-disable import/no-named-as-default-member */
const {
    Card: BaseCard,
    CardTitle: BaseCardTitle,
    CardImage,
    CardContent: BaseCardContent,
} = CardBase;
/*  eslint-enable import/no-named-as-default-member */

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray}
    display: flex;
    flex-direction: column;
`;

const PresentationContainer = styled.div`
    padding: 1.875rem;
    background-color: white;
    max-width: 720px;
    margin: 0 auto 2rem auto;
    border-bottom: solid 1px ${({ theme }) => theme.shadowColor};
    @media (max-width: 600px) {
        position: static;
        width: auto;
        height: auto;
        padding: 1em;
    }
`;

const TextContainer = styled.div`
    margin-top: 0;
    padding: 0.625rem;
    > p:first-child {
        margin-top: 0;
    }
`;

const SubTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.headlinesColor};

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const Text = styled.div`
    max-width: 720px;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
`;

const GridContainer = styled(MaxWidthContainer)`
    display: flex;
    justify-content: center;
    margin: 0 0 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        flex-basis: ${({ basis }) => basis || 'auto'}%;
        flex-grow: 1;
    }
`;

const Card = styled(BaseCard)`
    background-color: ${({ theme, opaque }) =>
        opaque ? theme.white : 'transparent'};
    ${({ theme, opaque }) =>
        opaque ? `border-bottom: 1px solid ${theme.shadowColor}` : null};
    margin: 0 0.5rem 0.5rem;
    max-width: 200px;
    min-width: 150px;
`;

const SkillContainer = styled.div`
    display: flex;
    > div {
        margin: 2em;
        min-width: 30%;
        h2 {
            font-size: 1em;
            padding-left: 30px;
        }

        ul {
            margin: 0;
        }
    }
    @media (max-width: 600px) {
        flex-wrap: wrap;

        > div {
            margin: 2em 0;
        }
    }
`;

const CardTitle = styled(BaseCardTitle)`
    font-size: 0.9rem;
    text-align: center;
    line-height: 1rem;
    margin: 0.5rem 0;
`;

const CardContent = styled(BaseCardContent)`
    text-align: center;
`;

const inceptionTasks = [
    { name: 'lean-startup', icon: <LeanStartup /> },
    { name: 'design-thinking', icon: <DesignThinking /> },
    { name: 'scrum', icon: <Scrum /> },
];

const projectManagementTasks = [
    { name: 'identify-impediments', icon: <Barrier /> },
    { name: 'self-organization', icon: <Teamwork /> },
    { name: 'delivery', icon: <PackageFactory /> },
    { name: 'value', icon: <UserLove /> },
];

const continuousImprovementTasks = [
    { name: 'knowledge-sharing', icon: <KnowledgeSharing /> },
    { name: 'lookout', icon: <Telescope /> },
];

const Facilitator = ({ headerImage, locale, t }) => (
    <Container>
        <Helmet title={t('seo.jobTitle')}>
            <html lang={locale} />
            <meta name="description" content={t('seo.jobDescription')} />
        </Helmet>
        <TopBar white role="presentation" />
        <header>
            <AppBar />
            <HeaderImage image={headerImage} />
        </header>
        <MaxWidthContainer role="main" width={1024}>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.facilitator.title'),
                }}
            />
            <PresentationContainer>
                <TextContainer
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.facilitator.intro'),
                    }}
                />
            </PresentationContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.facilitator.inception.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.facilitator.inception.intro'),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer>
                {inceptionTasks.map(({ name, icon }) => (
                    <Card key={name} opaque>
                        <CardImage>{icon}</CardImage>
                        <CardTitle>
                            {t(`jobs.facilitator.inception.${name}.name`)}
                        </CardTitle>
                        <CardContent>
                            {t(
                                `jobs.facilitator.inception.${name}.description`,
                            )}
                        </CardContent>
                    </Card>
                ))}
            </GridContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.facilitator.project-management.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.facilitator.project-management.intro'),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer basis={20}>
                {projectManagementTasks.map(({ name, icon }) => (
                    <Card key={name} opaque>
                        <CardImage>{icon}</CardImage>
                        <CardTitle>
                            {t(
                                `jobs.facilitator.project-management.${name}.name`,
                            )}
                        </CardTitle>
                        <CardContent>
                            {t(
                                `jobs.facilitator.project-management.${name}.description`,
                            )}
                        </CardContent>
                    </Card>
                ))}
            </GridContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.facilitator.continuous-improvement.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t(
                            'jobs.facilitator.continuous-improvement.intro',
                        ),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer basis={40}>
                {continuousImprovementTasks.map(({ name, icon }) => (
                    <Card key={name} opaque>
                        <CardImage>{icon}</CardImage>
                        <CardTitle>
                            {t(
                                `jobs.facilitator.continuous-improvement.${name}.name`,
                            )}
                        </CardTitle>
                        <CardContent>
                            {t(
                                `jobs.facilitator.continuous-improvement.${name}.description`,
                            )}
                        </CardContent>
                    </Card>
                ))}
            </GridContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.facilitator.apply.title'),
                }}
            />
            <PresentationContainer>
                <TextContainer
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.facilitator.apply.intro'),
                    }}
                />
                <SkillContainer>
                    <div>
                        <h2>{t('jobs.facilitator.apply.softSkills.title')}</h2>
                        <TextContainer
                            style={{ textAlign: 'left' }}
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    'jobs.facilitator.apply.softSkills.content',
                                ),
                            }}
                        />
                    </div>
                    <div>
                        <h2>{t('jobs.facilitator.apply.hardSkills.title')}</h2>
                        <TextContainer
                            style={{ textAlign: 'left' }}
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    'jobs.facilitator.apply.hardSkills.content',
                                ),
                            }}
                        />
                    </div>
                </SkillContainer>
            </PresentationContainer>
            <Text
                dangerouslySetInnerHTML={{
                    __html: t('jobs.facilitator.apply.outro'),
                }}
            />
            <div
                style={{
                    textAlign: 'center',
                    margin: '0.5rem auto 5rem',
                }}
            >
                <PrimaryLink href="mailto:contact@marmelab.com?subject=Interested in a facilitator position at marmelab">
                    {t('aboutus.contact_us')}
                </PrimaryLink>
            </div>
        </MaxWidthContainer>
        <Footer />
    </Container>
);

Facilitator.propTypes = {
    locale: PropTypes.string,
    headerImage: PropTypes.object,
    t: PropTypes.func,
};

export default compose(
    mapProps(({ data, locale }) => {
        return {
            locale,
            headerImage: data.headerImage,
        };
    }),
    translate(),
)(Facilitator);
