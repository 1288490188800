import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 64 64" {...props}>
        <path d="M62.79 38.15l-1.977-6.587A4.968 4.968 0 0 0 56.024 28H44.618l2.112-4.226 6.664-2.855-.788-1.838-7.336 3.145-2.544 5.086L35 19.586l-1.47 1.47L20.931 7.828A22.093 22.093 0 0 0 5 1v2a20.092 20.092 0 0 1 14.483 6.207l12.632 13.264-1.455 1.455L35 29.351v5.451A10.86 10.86 0 0 0 30.07 43H28v2h2v18h22V45h2v-2h-2.07A10.86 10.86 0 0 0 47 34.802V34h3.341l4.958 11.568A4.007 4.007 0 0 0 58.987 48C61.2 48 63 46.2 63 43.987v-4.4c0-.487-.071-.971-.21-1.437zM45 34h-8v-4h8v4zM35 22.414L40.586 28h-4.105l-3.141-3.926 1.66-1.66zM49.931 43H47v2h3v16H32V45h13v-2H32.069a8.915 8.915 0 0 1 4.829-7h8.204a8.913 8.913 0 0 1 4.829 7zM61 43.987a2.014 2.014 0 1 1-3.864.793L51.659 32H47v-2h9.024a2.98 2.98 0 0 1 2.873 2.138l1.977 6.587c.084.279.126.57.126.862v4.4z" />
        <path d="M34 47h2v2h-2zM38 47h2v2h-2zM42 47h2v2h-2zM46 47h2v2h-2zM11 15v-.343c0-1.336-.52-2.592-1.464-3.536L6 7.586l-3.536 3.535A4.968 4.968 0 0 0 1 14.657V15c0 2.757 2.243 5 5 5s5-2.243 5-5zm-2 0c0 1.654-1.346 3-3 3s-3-1.346-3-3v-.343c0-.802.312-1.556.878-2.122L6 10.414l2.122 2.121c.566.567.878 1.32.878 2.122V15zM12 23.586l-3.536 3.535A4.968 4.968 0 0 0 7 30.657V31c0 2.757 2.243 5 5 5s5-2.243 5-5v-.343c0-1.336-.52-2.592-1.464-3.536L12 23.586zM15 31c0 1.654-1.346 3-3 3s-3-1.346-3-3v-.343c0-.802.312-1.556.878-2.122L12 26.414l2.122 2.121c.566.567.878 1.32.878 2.122V31z" />
    </svg>
);

export default SvgComponent;
