import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 -20 128 128" {...props}>
        <path d="M123.5 106.4H4.5c-2.2 0-4-1.8-4-4V65.5c0-2.2 1.8-4 4-4h119c2.2 0 4 1.8 4 4v36.9c0 2.2-1.8 4-4 4zm0-40.9H4.5v36.9h119V65.5z" />
        <path d="M105.1 99.3H22.9c-8.5 0-15.3-6.9-15.3-15.3 0-8.5 6.9-15.3 15.3-15.3H105c8.5 0 15.3 6.9 15.3 15.3.1 8.4-6.8 15.3-15.2 15.3zM22.9 72.6c-6.2 0-11.3 5.1-11.3 11.3 0 6.2 5.1 11.3 11.3 11.3H105c6.2 0 11.3-5.1 11.3-11.3 0-6.2-5.1-11.3-11.3-11.3H22.9zM109.5 65.5H69.6c-1.1 0-2-.9-2-2V23.6c0-1.1.9-2 2-2h39.9c1.1 0 2 .9 2 2v39.9c0 1.1-.9 2-2 2zm-37.9-4h35.9V25.6H71.6v35.9z" />
        <path d="M93.5 35.9h-7.8c-.6 0-1-.4-1-1V23.6c0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1v11.3c0 .6-.5 1-1 1zm-6.8-2h5.8v-9.3h-5.8v9.3zM77.4 58.2c-.6 0-1-.4-1-1v-4.1c0-.6.4-1 1-1s1 .4 1 1v4.1c0 .6-.4 1-1 1zM85.7 58.2c-.6 0-1-.4-1-1v-4.1c0-.6.4-1 1-1s1 .4 1 1v4.1c0 .6-.5 1-1 1zM58.4 65.5H18.5c-1.1 0-2-.9-2-2V23.6c0-1.1.9-2 2-2h39.9c1.1 0 2 .9 2 2v39.9c0 1.1-.9 2-2 2zm-37.9-4h35.9V25.6H20.5v35.9z" />
        <path d="M42.3 35.9h-7.8c-.6 0-1-.4-1-1V23.6c0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1v11.3c0 .6-.4 1-1 1zm-6.8-2h5.8v-9.3h-5.8v9.3zM26.3 58.2c-.6 0-1-.4-1-1v-4.1c0-.6.4-1 1-1s1 .4 1 1v4.1c0 .6-.5 1-1 1zM34.5 58.2c-.6 0-1-.4-1-1v-4.1c0-.6.4-1 1-1s1 .4 1 1v4.1c0 .6-.4 1-1 1zM25.7 90.1c-3.4 0-6.1-2.8-6.1-6.1 0-3.4 2.8-6.1 6.1-6.1s6.1 2.8 6.1 6.1c.1 3.3-2.7 6.1-6.1 6.1zm0-10.3c-2.3 0-4.1 1.9-4.1 4.1s1.9 4.1 4.1 4.1c2.3 0 4.1-1.9 4.1-4.1s-1.8-4.1-4.1-4.1zM44.9 90.1c-3.4 0-6.1-2.8-6.1-6.1 0-3.4 2.8-6.1 6.1-6.1S51 80.6 51 84c0 3.3-2.8 6.1-6.1 6.1zm0-10.3c-2.3 0-4.1 1.9-4.1 4.1s1.9 4.1 4.1 4.1 4.1-1.8 4.1-4-1.9-4.2-4.1-4.2zM64 90.1c-3.4 0-6.1-2.8-6.1-6.1 0-3.4 2.8-6.1 6.1-6.1s6.1 2.8 6.1 6.1-2.7 6.1-6.1 6.1zm0-10.3c-2.3 0-4.1 1.9-4.1 4.1S61.8 88 64 88c2.3 0 4.1-1.9 4.1-4.1s-1.8-4.1-4.1-4.1zM83.1 90.1c-3.4 0-6.1-2.8-6.1-6.1 0-3.4 2.8-6.1 6.1-6.1s6.1 2.8 6.1 6.1c.1 3.3-2.7 6.1-6.1 6.1zm0-10.3c-2.3 0-4.1 1.9-4.1 4.1s1.9 4.1 4.1 4.1c2.3 0 4.1-1.9 4.1-4.1s-1.8-4.1-4.1-4.1zM102.3 90.1c-3.4 0-6.1-2.8-6.1-6.1 0-3.4 2.8-6.1 6.1-6.1s6.1 2.8 6.1 6.1-2.8 6.1-6.1 6.1zm0-10.3c-2.3 0-4.1 1.9-4.1 4.1s1.9 4.1 4.1 4.1 4.1-1.9 4.1-4.1-1.9-4.1-4.1-4.1z" />
    </svg>
);

export default SvgComponent;
