import React from 'react';

const SvgComponent = props => (
    <svg viewBox="-15 -15 96 96" {...props} stroke="black">
        <path d="M61.7 36.2H46.5V4c0-1.7-1.3-3-3-3h-30-.1c-.1 0-.2 0-.3.1H13c-.1 0-.2.1-.3.2L1.6 12.6c-.1.1-.1.2-.2.3v.1c0 .1 0 .2-.1.3v45.4c0 1.7 1.3 3 3 3h14v.3c0 1.7 1.3 3 3 3h40.4c1.7 0 3-1.3 3-3V39.2c0-1.6-1.3-3-3-3zM41.5 54L22.1 38.2h38.8L41.5 54zm-21.2 6.6V39.3l12.5 10.2-12.5 12.1v-1zm14.1-9.9c6.6 5.2 6.8 6 7.8 5.3l6.5-5.3L61.2 63H21.8l12.6-12.3zm15.8-1.2l12.5-10.2v22.3L50.2 49.5zM12.6 4.4v7.9H4.7l7.9-7.9zM4.3 59.6c-.6 0-1-.5-1-1V14.3h10.3c.6 0 1-.4 1-1V3h29c.6 0 1 .5 1 1v32.2H21.3c-1.6 0-3 1.3-3 3v20.4h-14z" />
        <path d="M19.5 11.6h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.5 1 1 1zM25.5 11.6h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.5 1 1 1zM31.6 11.6h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.4 1 1 1zM37.6 11.6h2c.6 0 1-.4 1-1s-.4-1-1-1h-2c-.6 0-1 .4-1 1s.4 1 1 1zM7.9 21.9h31.7c.6 0 1-.4 1-1s-.4-1-1-1H7.9c-.6 0-1 .4-1 1s.5 1 1 1zM7.9 32.3h18.6c.6 0 1-.4 1-1s-.4-1-1-1H7.9c-.6 0-1 .4-1 1 0 .5.5 1 1 1zM13.5 40.6H7.9c-.6 0-1 .4-1 1s.4 1 1 1h5.5c.6 0 1-.4 1-1 .1-.5-.4-1-.9-1zM13.5 51H7.9c-.6 0-1 .4-1 1s.4 1 1 1h5.5c.6 0 1-.4 1-1 .1-.6-.4-1-.9-1zM39.5 41.3h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1zM45.5 41.3h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1z" />
    </svg>
);

export default SvgComponent;
