import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 100 100" {...props}>
        <path d="M-78.4 8.1c6.1 0 11 4.9 11 11s-4.9 11-11 11-11-4.9-11-11 4.9-11 11-11m0-4c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15zM-46.6 59.9c6.1 0 11 4.9 11 11s-4.9 11-11 11-11-4.9-11-11 4.9-11 11-11m0-4c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15zM-110.3 59.9c6.1 0 11 4.9 11 11s-4.9 11-11 11-11-4.9-11-11 4.9-11 11-11m0-4c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.8-15-15-15zM-1607.1 8.5c13.3 0 24.1 10.8 24.1 24.1s-10.8 24.1-24.1 24.1-24.1-10.8-24.1-24.1 10.8-24.1 24.1-24.1m0-4c-15.5 0-28.1 12.6-28.1 28.1s12.6 28.1 28.1 28.1 28.1-12.6 28.1-28.1-12.5-28.1-28.1-28.1z" />
        <path d="M-1567.3 8.5c13.3 0 24.1 10.8 24.1 24.1s-10.8 24.1-24.1 24.1-24.1-10.8-24.1-24.1 10.8-24.1 24.1-24.1m0-4c-15.5 0-28.1 12.6-28.1 28.1s12.6 28.1 28.1 28.1c15.5 0 28.1-12.6 28.1-28.1s-12.6-28.1-28.1-28.1z" />
        <path d="M-1588.2 43.3c13.3 0 24.1 10.8 24.1 24.1s-10.8 24.1-24.1 24.1-24.1-10.8-24.1-24.1 10.8-24.1 24.1-24.1m0-4c-15.5 0-28.1 12.6-28.1 28.1s12.6 28.1 28.1 28.1c15.5 0 28.1-12.6 28.1-28.1s-12.6-28.1-28.1-28.1z" />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeMiterlimit={10}
            d="M-1378.5 33.2c6.3-17.3 22.9-29.7 42.4-29.7 24.9 0 45.1 20.2 45.1 45.1s-20.2 45.1-45.1 45.1c-20.2 0-37.4-13.3-43.1-31.7"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M-1373.9 56.6c-1.2 3.4-3.2 5.5-5.3 5.5-3.8 0-6.8-6.5-6.8-14.5s3-14.5 6.8-14.5c2.2 0 4.1 2.2 5.3 5.5"
        />
        <circle cx={-1307.1} cy={39} r={4.5} />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M-1298.3 72.7c-14.9 0-27.1-7.5-27.8-16.9"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeMiterlimit={10}
            d="M-1447 55.5h1.6c16.2 0 29.4 11.2 29.4 27.4v1c0 6.6-7.4 14-14 14h-61.9c-6.6 0-14-7.4-14-14v-1c0-16.2 13.2-27.4 29.4-27.4M-1476.6 55.5h1.9"
        />
        <path d="M-1461 5.8c14.2 0 25.8 11.6 25.8 25.8s-11.6 25.8-25.8 25.8-25.8-11.6-25.8-25.8 11.6-25.8 25.8-25.8m0-4c-16.5 0-29.8 13.4-29.8 29.8s13.4 29.8 29.8 29.8 29.8-13.4 29.8-29.8-13.3-29.8-29.8-29.8zM-1250.7 10.1c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1m0-4c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1-3.2-7.1-7.1-7.1zM-1176.9 10.1c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1m0-4c-3.9 0-7.1 3.2-7.1 7.1s3.2 7.1 7.1 7.1 7.1-3.2 7.1-7.1-3.2-7.1-7.1-7.1zM-1250.7 83c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1m0-4c-3.9 0-7.1 3.2-7.1 7.1 0 3.9 3.2 7.1 7.1 7.1s7.1-3.2 7.1-7.1c0-3.9-3.2-7.1-7.1-7.1zM-1176.9 83c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1-3.1-1.4-3.1-3.1 1.4-3.1 3.1-3.1m0-4c-3.9 0-7.1 3.2-7.1 7.1 0 3.9 3.2 7.1 7.1 7.1s7.1-3.2 7.1-7.1c0-3.9-3.2-7.1-7.1-7.1z" />
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeMiterlimit={10}
        >
            <path d="M-1244 13h5" />
            <path strokeDasharray="12 9" d="M-1229 13h37" />
            <path d="M-1188 13h5" />
        </g>
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeMiterlimit={10}
        >
            <path d="M-1246.2 81.8l3.5-3.6" />
            <path strokeDasharray="9.7363 7.7891" d="M-1237.2 72.7l46.8-46.8" />
            <path d="M-1187.6 23.2l3.5-3.6" />
        </g>
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeMiterlimit={10}
        >
            <path d="M-1244 86h5" />
            <path strokeDasharray="12 9" d="M-1229 86h37" />
            <path d="M-1188 86h5" />
        </g>
        <path d="M-1048.6 26.7v13.1h-87V26.7h87m0-4h-87c-2.2 0-4 1.8-4 4v13.1c0 2.2 1.8 4 4 4h87c2.2 0 4-1.8 4-4V26.7c0-2.2-1.8-4-4-4z" />
        <path d="M-1054.4 43.9v49.4h-75.4V43.9h75.4m4-4h-83.4v53.4c0 2.2 1.8 4 4 4h75.4c2.2 0 4-1.8 4-4V39.9z" />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-1093 95V25"
        />
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
        >
            <path d="M-1073 4.3c5.6 0 10.2 4.6 10.2 10.2s-4.6 10.2-10.2 10.2M-1092.5 22.6s7.3-18.3 19.6-18.3" />
        </g>
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
        >
            <path d="M-1112 4.3c-5.6 0-10.2 4.6-10.2 10.2s4.6 10.2 10.2 10.2M-1092.5 22.6s-7.3-18.3-19.6-18.3" />
        </g>
        <path d="M-934 9c3.3 0 6 2.7 6 6v70c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6V15c0-3.3 2.7-6 6-6h70m0-4h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10V15c0-5.5-4.5-10-10-10z" />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-970 6v87M-925 50h-44"
        />
        <path d="M-843.5 30.9l25.8 19.1-25.8 19.1-25.8-19.1 25.8-19.1m0-4c-.8 0-1.7.3-2.4.8l-25.8 19.1c-1 .8-1.6 1.9-1.6 3.2s.6 2.5 1.6 3.2l25.8 19.1c.7.5 1.5.8 2.4.8s1.7-.3 2.4-.8l25.8-19.1c1-.8 1.6-1.9 1.6-3.2s-.6-2.5-1.6-3.2l-25.8-19.1c-.7-.5-1.5-.8-2.4-.8zM-843.5 7c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5 1.6-3.5 3.5-3.5m0-4c-4.2 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5c4.2 0 7.5-3.4 7.5-7.5s-3.3-7.5-7.5-7.5zM-882.5 86c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5c0-2 1.6-3.5 3.5-3.5m0-4c-4.2 0-7.5 3.4-7.5 7.5 0 4.2 3.4 7.5 7.5 7.5 4.2 0 7.5-3.4 7.5-7.5 0-4.2-3.3-7.5-7.5-7.5zM-803.5 86c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5-3.5-1.6-3.5-3.5c0-2 1.5-3.5 3.5-3.5m0-4c-4.2 0-7.5 3.4-7.5 7.5 0 4.2 3.4 7.5 7.5 7.5 4.2 0 7.5-3.4 7.5-7.5 0-4.2-3.4-7.5-7.5-7.5z" />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M-844 17v12"
        />
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M-883 83v-4" />
            <path strokeDasharray="8 8" d="M-883 71V58" />
            <path d="M-883 54v-4h4M-874.4 49.9h4" />
        </g>
        <g
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M-804 83v-4" />
            <path strokeDasharray="8 8" d="M-804 71V58" />
            <path d="M-804 54v-4h-4M-812.2 49.9h-4" />
        </g>
        <path d="M-691.3 9.1c3.3 0 6 2.7 6 6v50.7c0 3.3-2.7 6-6 6h-69.3c-3.3 0-6-2.7-6-6V15.1c0-3.3 2.7-6 6-6h69.3m0-4h-69.3c-5.5 0-10 4.5-10 10v50.7c0 5.5 4.5 10 10 10h69.3c5.5 0 10-4.5 10-10V15.1c0-5.5-4.5-10-10-10z" />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-682 85h-87M-682 95h-87"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeMiterlimit={10}
            d="M-767 9.1l82.3 62.1M-685.6 10L-767 71.3"
        />
        <path d="M-581.4 7c2.2 0 4 1.8 4 4v78c0 2.2-1.8 4-4 4h-33.1c-2.2 0-4-1.8-4-4V11c0-2.2 1.8-4 4-4h33.1m0-4h-33.1c-4.4 0-8 3.6-8 8v78c0 4.4 3.6 8 8 8h33.1c4.4 0 8-3.6 8-8V11c0-4.4-3.6-8-8-8z" />
        <path d="M-581.4 7.3c2.2 0 4 1.8 4 4v66h-41.1v-66c0-2.2 1.8-4 4-4h33.1m0-4h-33.1c-4.4 0-8 3.6-8 8v70h49.1v-70c0-4.4-3.6-8-8-8z" />
        <circle cx={-598.1} cy={87} r={4.1} />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeMiterlimit={10}
            d="M-454 80h-1.7 25.7"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-479.8 20.8c-16.2 0-28.5 13.1-28.5 29.2 0 10.1 5 19 12.7 24.2l.9.6"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-433.4 72.9l6.4 6.3-6.4 6.4M-468.3 27.1l-6.4-6.3 6.4-6.4"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-473.7 21.2c16.2 0 29.4 13.2 29.4 29.4s-13 29.4-29.2 29.4H-523"
        />
        <path d="M-347.6 14.7c21.5 0 39 17.5 39 39s-17.5 39-39 39-39-17.5-39-39 17.5-39 39-39m0-4c-23.7 0-43 19.3-43 43s19.3 43 43 43 43-19.3 43-43-19.3-43-43-43z" />
        <path d="M-347.6 27.7c14.3 0 26 11.7 26 26s-11.7 26-26 26-26-11.7-26-26 11.7-26 26-26m0-4c-16.6 0-30 13.4-30 30s13.4 30 30 30 30-13.4 30-30-13.4-30-30-30z" />
        <path d="M-347.6 41.7c6.6 0 12 5.4 12 12s-5.4 12-12 12-12-5.4-12-12 5.4-12 12-12m0-4c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z" />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-347.6 53.5l33.8-33.8"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeLinejoin="round"
            strokeMiterlimit={10}
            d="M-304.3 19.4h-9.2v-9.2l6.9-6.9v9.2h9.2z"
        />
        <circle cx={-347.7} cy={53.7} r={4} />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-235.2 23.2l7.9 13.1-8.2 13.1h-16.1l-8-13 8-13h16.6m-.6-2.9h-16.1c-1 0-2 .2-2.6 1.1l-8 13c-.6 1-.6 2.1 0 3l8 13.3c.5.9 1.5 1.5 2.6 1.5h16.1c1 0 2-.7 2.6-1.6l8-13.2c.6-1 .6-2.2 0-3.1l-8-12.9c-.6-.8-1.5-1.1-2.6-1.1h0z"
        />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-208.7 8.2l7.9 13.1-8.1 13.1H-225l-8-13 8-13h15.9m.2-2.9H-225c-1 0-2 .3-2.6 1.2l-8 13.1c-.6 1-.6 2.1 0 3.1l8 13.2c.5.9 1.5 1.5 2.6 1.5h16.1c1 0 2-.6 2.6-1.5l8-13.2c.6-1 .6-2.2 0-3.1l-8-12.9c-.6-1.1-1.5-1.4-2.6-1.4h0z"
        />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-208.4 37.2l8 13.1-8 13.1h-16.1l-8-13 8-13h16.5m-.4-2.9h-16.1c-1 0-2 .2-2.6 1.1l-8 13c-.6 1-.6 2.1 0 3l8 13.3c.5.9 1.5 1.6 2.6 1.6h16.1c1 0 2-.7 2.6-1.6l8-13.3c.6-1 .6-2.2 0-3.2l-8-12.9c-.6-.8-1.6-1-2.6-1h0z"
        />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-182 22.5l8 13.1-8 12.9h-16.1l-8-13 8-13h16.1m0-3h-16.1c-1 0-2 .5-2.6 1.4l-8 13.2c-.6 1-.6 2.1 0 3.1l8 13c.5.9 1.5 1.3 2.6 1.3h16.1c1 0 2-.4 2.6-1.3l8-13.1c.6-1 .6-2.1 0-3.1l-8-13.1c-.6-.9-1.6-1.4-2.6-1.4h0z"
        />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-181.1 51.2l8 13.1-8.1 13.1h-16.1l-8-13 8-13h16.3m-.3-2.9h-16.1c-1 0-2 .2-2.6 1.1l-8 13c-.6 1-.6 2.1 0 3l8 13.3c.5.9 1.5 1.6 2.6 1.6h16.1c1 0 2-.7 2.6-1.6l8-13.3c.6-1 .6-2.2 0-3.2l-8-12.8c-.6-.9-1.5-1.1-2.6-1.1h0z"
        />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-207.6 66.2l7.9 13.1-8.1 13.1h-16.1l-8-13 8-13h16.8m-.7-2.9h-16.1c-1 0-2 .1-2.6 1l-8 13c-.6 1-.6 2.1 0 3l8 13.4c.5.9 1.5 1.6 2.6 1.6h16.1c1 0 2-.8 2.6-1.7l8-13.3c.6-1 .6-2.2 0-3.2l-8-12.8c-.6-.9-1.5-1-2.6-1h0z"
        />
        <path
            stroke="#000"
            strokeMiterlimit={10}
            d="M-234.9 52.2l8 13.1-8 13.1H-251l-8-13 8-13h16m.1-2.9H-251c-1 0-2 .2-2.6 1.1l-8 13c-.6 1-.6 2.1 0 3l8 13.3c.5.9 1.5 1.6 2.6 1.6h16.1c1 0 2-.7 2.6-1.6l8-13.3c.6-1 .6-2.2 0-3.2l-8-12.8c-.6-.9-1.6-1.1-2.6-1.1h0z"
        />
        <path
            fill="none"
            stroke="#000"
            strokeWidth={4}
            strokeMiterlimit={10}
            d="M-113.9 58.5c-.2-1.5-.3-3.1-.3-4.7 0-15.3 9.5-28.3 23-33.5M-54.8 80.7c-6.3 5.5-14.6 8.9-23.6 8.9-9 0-17.3-3.4-23.6-8.9M-65.6 20.3c13.4 5.2 23 18.2 23 33.5 0 1.6-.1 3.1-.3 4.6"
        />
        <g>
            <path d="M89 14.8c3.3 0 6 2.7 6 6v58.4c0 3.3-2.7 6-6 6H11c-3.3 0-6-2.7-6-6V20.8c0-3.3 2.7-6 6-6h78m0-4H11c-5.5 0-10 4.5-10 10v58.4c0 5.5 4.5 10 10 10h78c5.5 0 10-4.5 10-10V20.8c0-5.5-4.5-10-10-10z" />
            <path
                fill="none"
                stroke="#000"
                strokeWidth={4}
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M66 50H34"
            />
            <path d="M89 14.8c3.3 0 6 2.7 6 6v58.4c0 3.3-2.7 6-6 6H68.2V14.8H89m0-4H64.2v78.4H89c5.5 0 10-4.5 10-10V20.8c0-5.5-4.5-10-10-10zM31.8 14.8v70.4H11c-3.3 0-6-2.7-6-6V20.8c0-3.3 2.7-6 6-6h20.8m4-4H11c-5.5 0-10 4.5-10 10v58.4c0 5.5 4.5 10 10 10h24.8V10.8z" />
        </g>
    </svg>
);

export default SvgComponent;
