import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 100 100" {...props}>
        <switch>
            <g>
                <path d="M38.2 61.6c-1.1-4.6-4.9-8-9.5-8.5 2-1.9 3.3-4.6 3.3-7.6v-3.8c0-5.8-4.7-10.5-10.5-10.5C15.7 31.3 11 36 11 41.7v3.8c0 3 1.3 5.7 3.3 7.6-4.6.5-8.4 3.8-9.5 8.5L2.6 71c-.3 1.4 0 2.9.9 4.1.9 1.2 2.3 1.8 3.8 1.8h28.2c1.5 0 2.9-.7 3.8-1.8.9-1.2 1.3-2.6.9-4.1l-2-9.4zM13.9 45.5v-3.8c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5v3.8c0 4.1-3.4 7.5-7.5 7.5s-7.5-3.4-7.5-7.5zM37 73.2c-.4.4-.9.7-1.5.7H7.3c-.6 0-1.1-.3-1.5-.7-.4-.5-.5-1-.4-1.6l2.1-9.4c.8-3.7 4.1-6.3 7.8-6.3h12c3.8 0 7 2.6 7.8 6.3l2.1 9.4c.3.6.2 1.2-.2 1.6zM97.4 71l-2.1-9.4c-1.1-4.6-4.9-8-9.5-8.5 2-1.9 3.3-4.6 3.3-7.6v-3.8c0-5.8-4.7-10.5-10.5-10.5s-10.5 4.7-10.5 10.5v3.8c0 3 1.3 5.7 3.3 7.6-4.6.5-8.4 3.8-9.5 8.5L59.7 71c-.3 1.4 0 2.9.9 4.1.9 1.2 2.3 1.8 3.8 1.8h28.2c1.5 0 2.9-.7 3.8-1.8 1-1.2 1.3-2.7 1-4.1zM71 45.5v-3.8c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5v3.8c0 4.1-3.4 7.5-7.5 7.5S71 49.6 71 45.5zm23.1 27.7c-.4.4-.9.7-1.5.7H64.4c-.6 0-1.1-.3-1.5-.7-.4-.5-.5-1-.4-1.6l2.1-9.4c.8-3.7 4.1-6.3 7.8-6.3h12c3.8 0 7 2.6 7.8 6.3l2.1 9.4c.3.6.2 1.2-.2 1.6zM63.4 36.1c0-7.2-5.8-13-13-13s-13 5.8-13 13v13.1c0 1.2.7 2.2 1.7 2.8.4.2.9.3 1.3.3.7 0 1.4-.2 1.9-.7l3-2.5h4.9c7.4 0 13.2-5.9 13.2-13zm-13 10H45c-.3 0-.7.1-.9.3l-3.5 2.8h-.1c-.1 0-.1-.1-.1-.1v-13c0-5.5 4.5-10 10-10s10 4.5 10 10-4.4 10-10 10z" />
                <circle cx={44.4} cy={36.1} r={2} />
                <circle cx={50.4} cy={36.1} r={2} />
                <circle cx={56.5} cy={36.1} r={2} />
            </g>
        </switch>
    </svg>
);

export default SvgComponent;
