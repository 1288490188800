import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 100 100" {...props}>
        <path
            style={{
                textIndent: 0,
                textTransform: 'none',
                blockProgression: 'tb',
            }}
            d="M50.015 10.012c-7.711 0-14.005 6.29-14.005 13.998 0 7.707 6.294 13.998 14.005 13.998 7.711 0 14.005-6.29 14.005-13.998 0-7.707-6.294-13.998-14.005-13.998zm0 4a9.97 9.97 0 0 1 10.004 9.998 9.97 9.97 0 0 1-10.004 9.998 9.97 9.97 0 0 1-10.004-9.998 9.97 9.97 0 0 1 10.004-9.999zm0 27.995c-10.96 0-20.376 7.646-22.82 17.966-4.728 1.254-8.727 3.636-11.63 6.624-.198.214-7.44 20.747-7.44 20.747-.33.906.109 2.024.966 2.464.858.44 2.023.144 2.567-.652 3.18-4.567 10.44-8.155 18.35-8.155 7.964 0 15.181 3.518 18.35 8.124.73 1.077 2.584 1.077 3.314 0 3.054-4.44 9.806-7.879 17.412-8.124 1.048-.033 1.971-1.015 1.939-2.062-.033-1.047-1.016-1.97-2.064-1.937-6.617.213-12.648 2.514-16.943 6.155V67.066c3.508-2.28 8.385-4.062 13.286-4.062 6.837 0 12.482 2.658 16.037 6.187l4.251 12.123c-2.398-1.445-5.16-2.521-8.096-3.25-1.047-.346-2.315.357-2.574 1.428-.26 1.072.546 2.277 1.636 2.447 5.234 1.299 9.563 3.98 11.817 7.217.543.796 1.708 1.092 2.566.652.858-.44 1.296-1.558.966-2.464 0 0-7.242-20.533-7.44-20.747-2.903-2.988-6.902-5.37-11.63-6.624-2.444-10.32-11.86-17.966-22.82-17.966zm0 4c8.472 0 15.814 5.554 18.413 13.185a29.925 29.925 0 0 0-3.126-.187c-5.785 0-11.176 2-15.287 4.655-4.111-2.655-9.502-4.655-15.287-4.655-1.07 0-2.103.08-3.126.187 2.6-7.631 9.941-13.185 18.413-13.185zM34.728 63.004c4.901 0 9.778 1.781 13.286 4.062v16.091c-4.53-3.833-10.99-6.155-18.006-6.155-5.84 0-11.264 1.73-15.6 4.468l4.283-12.28c3.555-3.528 9.2-6.186 16.037-6.186z"
            overflow="visible"
            color="#000"
        />
    </svg>
);

export default SvgComponent;
