import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 0 64 64" {...props}>
        <path d="M54 14H11c-.6 0-1 .4-1 1v26c0 .6.4 1 1 1h43c.6 0 1-.4 1-1V15c0-.6-.4-1-1-1zm-1 26H12V16h41v24z" />
        <path d="M59 44.9V11c0-.6-.4-1-1-1H7c-.6 0-1 .4-1 1v33.8L1 60.7c0 .3 0 .6.2.9s.5.4.8.4h60c.3 0 .6-.1.8-.4s.3-.6.2-.9l-4-15.8zM8 12h49v32H8V12zM3.4 60l4.4-14h49.5l3.5 14H3.4z" />
        <path d="M13 48h39v2H13zM13 52h39v2H13zM28 56h3v2h-3zM33 56h3v2h-3zM40.267 36.318l8.98-8.98 1.414 1.414-8.98 8.98zM33.267 37.318l8.98-8.98 1.414 1.414-8.98 8.98z" />
    </svg>
);

export default SvgComponent;
