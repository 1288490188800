import React from 'react';

const SvgComponent = props => (
    <svg viewBox="0 -28 256 256" preserveAspectRatio="xMinYMin meet" {...props}>
        <g>
            <path d="M69.762 201.735l-31.825-11.417v-46.646l31.825 10.844v47.22M75.598 201.735l31.824-11.417v-46.646l-31.824 10.844v47.22M41.207 140.184l32.21-9.066 29.865 9.629-29.865 10.138-32.21-10.701M31.873 136.57L.05 125.154V78.507L31.873 89.35v47.221M37.71 136.57l31.825-11.416V78.507L37.71 89.35v47.221M3.32 75.016l32.208-9.065 29.867 9.63-29.867 10.14L3.32 75.016" />
            <g>
                <path d="M106.132 136.57l-31.825-11.416V78.507l31.825 10.842v47.221M111.969 136.57l31.825-11.416V78.507L111.97 89.35v47.221M77.578 75.016l32.21-9.065 29.864 9.63-29.865 10.14-32.21-10.705" />
            </g>
            <g>
                <path d="M144.02 71.403l-31.825-11.414V13.342l31.825 10.841v47.22M149.857 71.403l31.825-11.414V13.342l-31.825 10.841v47.22M115.466 9.852l32.21-9.067 29.864 9.63-29.865 10.14-32.209-10.703" />
            </g>
            <g>
                <path d="M218.28 71.403l-31.826-11.414V13.342l31.825 10.841v47.22M224.116 71.403l31.825-11.414V13.342l-31.825 10.841v47.22M189.725 9.852l32.21-9.067 29.863 9.63-29.864 10.14-32.209-10.703" />
            </g>
        </g>
    </svg>
);

export default SvgComponent;
